import icons from "../icons/icons"
import Parallax from "parallax-js"
// const $ = require("jquery"); // if we need
import Swiper, { Autoplay, Navigation, Lazy } from "swiper"
import "swiper/swiper-bundle.css"
import ModalVideo from "modal-video"
import "modal-video/css/modal-video.css"
import "animate.css"
Swiper.use([Navigation, Autoplay, Lazy])
import anime from "animejs/lib/anime.es.js"
import { Fancybox } from "@fancyapps/ui"
// import "fancyapps/ui/dist/fancybox.css";
import "@fancyapps/ui/dist/fancybox.css"
import LazyLoad from "vanilla-lazyload"

var lazyLoadInstance = new LazyLoad({})
$("#datepicker").datepicker()

Fancybox.bind("[data-fancybox]", {
  // Your options go here
})
new ModalVideo(".js-modal-btn", {
  autoplay: 1,
})

var elem = document.querySelector(".main-scrollbar")
var scrollbar = Scrollbar.init(elem, {
  damping: 0.04,
  renderByPixels: true,
})

var textWrapper = document.querySelectorAll(".js-smooth-text")
if (textWrapper) {
  textWrapper.forEach((e) => {
    e.innerHTML = e.textContent.replace(/\S/g, "<span class='letter'>$&</span>")

    anime.timeline({ loop: false }).add({
      targets: ".js-smooth-text .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 1250,
      delay: (el, i) => 100 * (i + 1),
    })
  })
}

if ($(".scroll-animation").length > 0) {
  setTimeout(() => {
    var currentX = ""
    var currentY = ""
    var movementConstant = 0.015

    $(document).mousemove(function (e) {
      if (currentX == "") currentX = e.pageX
      var xdiff = e.pageX - currentX
      currentX = e.pageX
      if (currentY == "") currentY = e.pageY
      var ydiff = e.pageY - currentY
      currentY = e.pageY

      $(".js-mousemove").each(function (i, el) {
        var movement = (i + 1) * (xdiff * movementConstant)
        var movementy = (i + 1) * (ydiff * movementConstant)
        var newX = $(el).position().left + movement
        var newY = $(el).position().top + movementy
        var cssObj = {
          left: newX + "px",
          top: newY + "px",
        }
        $(el).css({
          transform: "translate(" + newX + "px," + newY + "px)",
        })
      })
    })
  }, 1500)
  var swiperOptions = {
    loop: true,
    direction: "vertical",
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    slidesPerView: "auto",
    speed: 6000,
    freeMode: true,
    observer: true,

    grabCursor: false,
    mousewheelControl: false,
    keyboardControl: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    observeParents: true,

    breakpoints: {
      320: {
        slidesPerView: 2.5,
      },
      769: {
        slidesPerView: "auto",
      },
    },
  }

  setTimeout(() => {
    $(".js-hero-images-slider").css("opacity", 1)
  }, 500)
  var heroImagesSwiper = new Swiper(".js-hero-images-slider", swiperOptions)

  var controller = new ScrollMagic.Controller({
    refreshInterval: 0,
  })

  // background changed scroll trigger
  var backgrounColorFormOutTween = TweenMax.fromTo(
    $(".main-content"),
    1,
    { css: { backgroundColor: "#005640" } },
    { css: { backgroundColor: "#fff" } }
  )

  var bgFormOutDuration = 500
  if ($(window).width() < 993) {
    var bgFormOutDuration = 100
  }

  var backgrounColorFormOutScene = new ScrollMagic.Scene({
    triggerElement: ".js-index-slider-section-trigger",
    duration: bgFormOutDuration,
  })
    .setTween(backgrounColorFormOutTween)
    .addTo(controller)

  // background changed scroll trigger
  var backgrounColorFormTween = TweenMax.fromTo(
    $(".main-content"),
    1,
    { css: { backgroundColor: "#fff" } },
    { css: { backgroundColor: "#005640" } }
  )

  var backgrounColorFormScene = new ScrollMagic.Scene({
    triggerElement: ".js-contact-section-trigger",
    triggerHook: 0.6,
    duration: 300,
  })
    .setTween(backgrounColorFormTween)
    .addTo(controller)

  // background changed scroll trigger
  var backgrounColorTween = TweenMax.fromTo(
    $(".main-content"),
    1,
    { css: { backgroundColor: "#005640" } },
    { css: { backgroundColor: "#fff" } }
  )

  var backgrounColorScene = new ScrollMagic.Scene({
    triggerElement: ".js-video-section-trigger",
    duration: 400,
  })
    .setTween(backgrounColorTween)
    .addTo(controller)

  // heroText scroll trigger
  var heroTextTween = TweenMax.fromTo(
    $(".hero__content .txt"),
    1,
    { css: { transform: "translateY(0px)", opacity: "1" } },
    { css: { transform: "translateY(300px)", opacity: "0" } }
  )

  var heroTextDur = 700
  var heroTextHook = 0.35
  if ($(window).width() < 577) {
    var heroTextDur = 600
    var heroTextHook = 0.35
  }
  var heroTextScene = new ScrollMagic.Scene({
    triggerElement: ".js-hero-txt-trigger",
    triggerHook: heroTextHook,
    duration: heroTextDur,
  })
    .setTween(heroTextTween)
    .addTo(controller)

  // heroImages scroll trigger
  var heroImagesTween = TweenMax.fromTo(
    $(".hero__images"),
    1,
    { css: { transform: "translateY(0px)", opacity: "1" } },
    { css: { transform: "translateY(-500px)", opacity: "0" } }
  )

  var heroImagesScene = new ScrollMagic.Scene({
    triggerElement: ".js-hero-txt-trigger",
    triggerHook: heroTextHook,
    duration: heroTextDur,
  })
    .setTween(heroImagesTween)
    .addTo(controller)

  var videoTextTween = TweenMax.fromTo(
    $(".js-video-text-scroll"),
    1,
    { css: { transform: "scale(0.5)", opacity: 0 } },
    { css: { transform: "scale(1)", opacity: 1 } }
  )
  var videoTextDur = 500
  var videoTextHook = 0.7

  if ($(window).width() < 769) {
    var videoTextDur = 300
    var videoTextHook = 0.9
  }
  var videoTextScene = new ScrollMagic.Scene({
    triggerElement: ".js-video-section-trigger",
    duration: videoTextDur,
    triggerHook: videoTextHook,
  })
    .setTween(videoTextTween)
    .addTo(controller)

  var videoLockTween = TweenMax.fromTo(
    $(".js-video-lock-scroll"),
    1,
    { css: { transform: "translateY(200px)", opacity: 0 } },
    { css: { transform: "translateY(0)", opacity: 1 } }
  )

  var videoLockScene = new ScrollMagic.Scene({
    triggerElement: "#video-lock-trigger",
    duration: 600,
  })
    .setTween(videoLockTween)
    .addTo(controller)

  var videoTextTweenTwo = TweenMax.fromTo(
    $(".js-video-text-scroll-two"),
    1,
    { css: { transform: "translateY(300px)", opacity: 0 } },
    { css: { transform: "translateY(0)", opacity: 1 } }
  )

  var videoTextSceneTwo = new ScrollMagic.Scene({
    triggerElement: "#video-lock-trigger",
    triggerHook: -2.5,
    duration: 500,
  })
    .setTween(videoTextTweenTwo)
    .addTo(controller)

  var locationLeftTween = TweenMax.fromTo(
    $(".location__item--left"),
    1,
    { css: { transform: "translateX(-500px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var locationLeftScene = new ScrollMagic.Scene({
    triggerElement: ".js-location-section-trigger",
    triggerHook: 0.5,
    duration: 300,
  })
    .setTween(locationLeftTween)
    .addTo(controller)

  var locationRightTween = TweenMax.fromTo(
    $(".location__item--right"),
    1,
    { css: { transform: "translateX(500px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var locationRightScene = new ScrollMagic.Scene({
    triggerElement: ".js-location-section-trigger",
    triggerHook: 0.5,
    duration: 300,
  })
    .setTween(locationRightTween)
    .addTo(controller)

  var farmTextTween = TweenMax.fromTo(
    $(".js-farm-text-scroll"),
    1,
    { css: { transform: "translateY(400px)" } },
    { css: { transform: "translateY(0)" } }
  )
  var farmMobileDuration = 600
  var farmMobileDurationHook = 0.35
  if ($(window).width() < 993) {
    var farmMobileDuration = 300
    var farmMobileDurationHook = 0.4
  }
  var farmTextScroll = new ScrollMagic.Scene({
    triggerElement: ".js-farm-section-trigger",
    triggerHook: farmMobileDurationHook,
    duration: farmMobileDuration,
  })
    .setTween(farmTextTween)
    .addTo(controller)

  var contentTextTween = TweenMax.fromTo(
    $(".js-content-row-text-scroll"),
    1,
    { css: { transform: "translateX(-300px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var contentTextScene = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.65,
    duration: 400,
  })
    .setTween(contentTextTween)
    .addTo(controller)

  var contentRightTween = TweenMax.fromTo(
    $(".js-content-row-right-scroll"),
    1,
    { css: { transform: "translateX(300px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var contentRightScene = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.65,
    duration: 400,
  })
    .setTween(contentRightTween)
    .addTo(controller)

  var contentImageTween = TweenMax.fromTo(
    $(".js-content-row-image-scroll"),
    1,
    { css: { transform: "translateY(300px)", opacity: 1 } },
    { css: { transform: "translateY(-200px)", opacity: 1 } }
  )

  var contentImageScene = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.2,
    duration: 1000,
  })
    .setTween(contentImageTween)
    .addTo(controller)

  var indexSliderTween = TweenMax.fromTo(
    $(".index-slider__wrapper"),
    1,
    { css: { transform: "translateY(300px)", opacity: 0 } },
    { css: { transform: "translateY(0px)", opacity: 1 } }
  )
  var indexSliderHook = 0.4
  if ($(window).width() < 993) {
    var indexSliderHook = 0.6
  }
  var indexSliderScene = new ScrollMagic.Scene({
    triggerElement: ".js-index-slider-section-trigger",
    triggerHook: indexSliderHook,
    duration: 200,
  })
    .setTween(indexSliderTween)
    .addTo(controller)

  // triggering with smooth-bar
  scrollbar.addListener(() => {
    videoTextScene.refresh()
    backgrounColorScene.refresh()
    heroTextScene.refresh()
    heroImagesScene.refresh()
    videoLockScene.refresh()
    videoTextSceneTwo.refresh()
    locationLeftScene.refresh()
    locationRightScene.refresh()
    farmTextScroll.refresh()
    contentTextScene.refresh()
    contentRightScene.refresh()
    contentImageScene.refresh()
    backgrounColorFormScene.refresh()
    backgrounColorFormOutScene.refresh()
    indexSliderScene.refresh()
  })
}

if ($(".detail-scroll-anim").length > 0) {
  var controller = new ScrollMagic.Controller({
    refreshInterval: 0,
  })
  var contentTextTweenDetail = TweenMax.fromTo(
    $(".js-content-row-text-scroll"),
    1,
    { css: { transform: "translateX(-300px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var contentTextSceneDetail = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.65,
    duration: 400,
  })
    .setTween(contentTextTweenDetail)
    .addTo(controller)

  var contentRightTweenDetail = TweenMax.fromTo(
    $(".js-content-row-right-scroll"),
    1,
    { css: { transform: "translateX(300px)", opacity: 0 } },
    { css: { transform: "translateX(0)", opacity: 1 } }
  )

  var contentRightSceneDetail = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.65,
    duration: 400,
  })
    .setTween(contentRightTweenDetail)
    .addTo(controller)

  var contentImageTweenDetail = TweenMax.fromTo(
    $(".js-content-row-image-scroll"),
    1,
    { css: { transform: "translateY(300px)", opacity: 1 } },
    { css: { transform: "translateY(-200px)", opacity: 1 } }
  )

  var contentImageSceneDetail = new ScrollMagic.Scene({
    triggerElement: ".js-content-row-section-trigger",
    triggerHook: 0.2,
    duration: 1000,
  })
    .setTween(contentImageTweenDetail)
    .addTo(controller)
  scrollbar.addListener(() => {
    contentTextSceneDetail.refresh()
    contentRightSceneDetail.refresh()
    contentImageSceneDetail.refresh()
  })
}

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },
  headerMenuFn() {
    $(".header__mobile-button").on("click", () => {
      $(".header__mobile-button #hamburger").toggleClass("open")
      $(".header").toggleClass("header--with-menu")
      $(".main-menu").toggleClass("active")
    })
  },
  videoContentPlayFollowFn() {
    var mouseX = 0,
      mouseY = 0
    var xp = 0,
      yp = 0

    $(".video-content__image").on("mouseenter", function () {})

    if ($(window).width() > 768) {
      function activated() {
        $(document).mousemove(function (e) {
          mouseX = e.pageX - 300
          mouseY = e.pageY - 170
        })

        setInterval(function () {
          xp += (mouseX - xp) / 8
          yp += (mouseY - yp) / 8
          $(".js-follow-mouse").css({ left: xp + "px", top: yp + "px" })
        }, 20)
      }
      $(".video-content__image").mouseenter(activated)
    }
  },
  headerLanguageFn() {
    const headerLanguageBtn = document.querySelector(".js-header-language-btn")
    if (headerLanguageBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__language").contains(e.target)) {
          headerLanguageBtn.parentNode.classList.toggle("opened")
        } else {
          headerLanguageBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".header__language-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".header__language-wrapper")
            .parentNode.classList.add("opened")
        }
      })
    }
  },
  headerStickyFn() {
    const header = document.querySelector(".header")
    if (header) {
      Scrollbar.getAll()[0].addListener(function (status) {
        if (status.offset.y > 50) {
          header.classList.add("header--dark")
        } else {
          header.classList.remove("header--dark")
        }

        $(".scroll-content").one("mousewheel", function (e) {
          if (e.originalEvent.wheelDelta / 120 > 0) {
            $(".header").removeClass("header--disabled")
          } else {
            $(".header").addClass("header--disabled")
          }
        })
      })
    }
  },
  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused")
    })
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      }
    })
  },

  heroImagesSliderFn() {},

  detailImgSliderFn() {
    var swiperOptions = {
      lazy: true,
      loop: true,
      slidesPerView: 1,
      speed: 700,
      navigation: {
        nextEl: ".detail-img .swiper-button-next",
        prevEl: ".detail-img .swiper-button-prev",
      },
      observeParents: true,
    }

    var detailImgSwiper = new Swiper(".js-detail-img-slider-1", swiperOptions)
    var detailImgSwiper2 = new Swiper(".js-detail-img-slider-2", swiperOptions)
  },

  indexSliderFn() {
    const indexSwiper = new Swiper(".js-index-slider", {
      loop: false,
      slidesPerView: 2,
      spaceBetween: 75,
      speed: 800,
      grabCursor: true,
      navigation: {
        nextEl: ".js-index-slider .swiper-button-next",
        prevEl: ".js-index-slider .swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1.3,
          spaceBetween: 25,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 40,
        },

        993: {
          spaceBetween: 75,
        },
      },
    })
  },

  detailSliderFn() {
    setTimeout(() => {
      const detailSwiper = new Swiper(".js-detail-slider", {
        loop: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        observer: false,
        grapCursor: false,
        speed: 800,
        breakpoints: {
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          769: {
            slidesPerView: 1.6,
            spaceBetween: 40,
          },

          993: {
            slidesPerView: 2.2,
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl: ".js-detail-slider .swiper-button-next",
          prevEl: ".js-detail-slider .swiper-button-prev",
        },
      })
    }, 300)
  },

  init: function () {
    app.iconSpriteFn()
    app.load()
    app.heroImagesSliderFn()
    app.headerMenuFn()
    app.videoContentPlayFollowFn()
    app.headerLanguageFn()
    app.headerStickyFn()
    app.inputFocusandTexted()
    app.indexSliderFn()
    app.detailSliderFn()
    app.detailImgSliderFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
